.ant-tooltip {
    max-width: 50%;

    .ant-tooltip-inner {
        background: $dropdown-background;
        border: 1px solid $dropdown-border-color;
    }

    .ant-tooltip-inner-content {
        color: $dropdown-text-color;
    }

    .ant-tooltip-arrow {
        display: none;
    }
}