.ant-picker {
  background: $input-background;
  border-color: $input-border-color;

  .ant-picker-input > input {
    color: $input-text-color;
    margin-top: 2px;
    margin-bottom: -2px;

    &::placeholder {
      color: transparentize($input-text-color, 0.5);
    }
  }

  .ant-picker-input-placeholder > input {
    color: $warning-color;
  }

  .anticon,
  .ant-picker-separator {
    color: $input-text-color;
  }

  .ant-picker-clear {
    background: $input-background;
  }

  &:hover,
  &-focused {
    border-color: $clickable-color;
  }

  .ant-picker-active-bar {
    background-color: $clickable-color;
  }

  .ant-picker-range-arrow {
    opacity: 0;
  }

  &.ant-picker-disabled {
    opacity: 0.5;
    color: $input-text-color !important;
    background-color: $input-background;
    border-color: $input-border-color;
  }
}

.ant-picker-dropdown {
  background: $dropdown-background;
  border: 1px solid $dropdown-border-color;

  .ant-picker-month-btn,
  .ant-picker-year-btn,
  .ant-picker-decade-btn,
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-header-next-btn {
    color: $input-text-color;
    font-weight: 600;

    &:hover {
      color: $select-color;
    }
  }

  .ant-picker-range-arrow {
    display: none;
  }

  .ant-picker-panel-container {
    background: transparent;
  }

  .ant-picker-header,
  .ant-picker-panel {
    color: $dropdown-text-color;
    border-bottom: none;
  }

  .ant-picker-content th {
    color: $dropdown-text-color;
    font-weight: 600;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    padding: 2px 3px 0 3px;
    font-weight: 600;
    color: transparentize($dropdown-text-color, 0.9);
  }

  .ant-picker-cell-in-view .ant-picker-cell-inner {
    color: transparentize($dropdown-text-color, 0.5);
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background-color: $input-text-color;
    color: $background-color !important;
  }

  .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
    opacity: 0.5;
  }

  .ant-picker-cell-range-end:before,
  .ant-picker-cell-range-start:before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: transparentize($dropdown-text-color, 0.8) !important;
  }

  .ant-picker-cell-range-hover-end:after,
  .ant-picker-cell-range-hover-start:after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:after {
    border-color: $input-text-color !important;
  }

  .ant-picker-cell::before {
    top: 49%;
  }

  .ant-picker-cell-disabled {
    opacity: 0.5;
  }

  .ant-picker-cell-disabled:before {
    background: rgba(0, 0, 0, 0.2);
  }

  .ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $input-text-color;
  }

  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid $input-border-color;
  }

  .ant-picker-today-btn {
    color: $clickable-color;
    font-weight: 600;

    &:hover {
      color: $select-color;
    }
  }
}
