.ant-tag {
  color: $select-text-color;
  background-color: $select-item-background;
  border-color: $select-border-color;

  .anticon-close {
    color: $select-text-color;
  }
}

.ant-table-content,
.ant-drawer-content {
  .ant-tag {
    margin-bottom: $margin-xxs;
  }
}
