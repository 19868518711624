.ant-badge {
  .ant-badge-count {
    font-size: $badge-font-size;
    height: $badge-height;
    min-width: $badge-height;
    border-radius: 4px;
    padding: 0 2px;
    background: $badge-color;
    border-bottom: none;
    box-shadow: none;
  }

  .ant-scroll-number {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-scroll-number-only {
    height: $badge-height;

    p.ant-scroll-number-only-unit {
      display: flex;
      align-items: center;
      height: $badge-height;
      color: $color-white;
    }
  }
}
