.ant-typography {
  color: $typography-color;
  letter-spacing: .6px;

  &.ant-typography-secondary {
    color: $text-light-color-4;
  }

  &.ant-typography-danger {
    color: $error-color;
  }

  &.ant-typography-warning {
    color: $warning-color;
  }
}

a.ant-typography {
  color: $typography-link-color;

  &:hover {
    color: $typography-link-active-color;
  }
}

h3.ant-typography, .ant-typography h3,
h4.ant-typography {
  color: $typography-color;
}

.ant-typography-edit {
  color: $typography-color;

  &:active,
  &:focus,
  &:hover {
    color: $typography-link-active-color;
  }
}