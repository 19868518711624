.ant-card {
  background: $card-background;
  color: $card-text-color;
  border: 1px solid $card-border-color;
  max-width: 100%;
  // padding: $card-padding;

  .ant-card-head {
    color: $card-text-color;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: none;
  }

  &.card-gradient {
    background: $card-background-gradient;
  }


}

@media screen and (max-width: $mobile-screen) {
  .ant-card {

    .ant-card-head .ant-card-head-wrapper {
      display: flex;
      flex-direction: column;

      .ant-card-head-title {
        width: 100%;
        margin-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-card-extra {
        margin-left: 0px;
        padding: 0px;
        justify-content: center;
      }
    }
  }
}