.ant-steps {
  .ant-steps-item-container {
    .ant-steps-item-content > .ant-steps-item-title {
      color: $steps-text-color;
      font-size: 18px;
    }

    .ant-steps-item-content > .ant-steps-item-description {
      color: $steps-text-color;
      font-size: 13px;
    }

    .ant-steps-icon {
      color: $steps-text-color;
    }

    .ant-steps-item-icon {
      line-height: 35px;
    }
  }

  .ant-steps-item-icon,
  .ant-steps-item-content {
    display: inline-block;
    vertical-align: baseline;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: $select-color;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: $steps-active-text-color;
  }

  ant-steps-item-description .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: $error-color;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: $steps-line-background;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background: transparent;
    border-color: $steps-line-background;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: $clickable-color;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: transparent;
  }
}
