.ant-menu {
  font-size: $menu-font-size;
  text-transform: uppercase;

  &-horizontal {
    background: $menu-horizontal-background;
    border: none;

    & > .ant-menu-item,
    .ant-menu-submenu {
      &:hover {
        border-color: transparent;
      }
    }

    .ant-menu-light {
      background-color: transparent;
    }

    &:not(.ant-menu-dark) > .ant-menu-item-active,
    &:not(.ant-menu-dark) > .ant-menu-item:hover,
    &:not(.ant-menu-dark) > .ant-menu-item-selected,
    &:not(.ant-menu-dark) > .ant-menu-submenu-selected,
    &:not(.ant-menu-dark) > .ant-menu-submenu-open,
    &:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    & > .ant-menu-item:hover,
    & > .ant-menu-item-active,
    & > .ant-menu-submenu:hover {
      color: $menu-select-color;
      border-color: transparent;
    }
  }

  &-inline,
  &-inline .ant-menu-submenu > .ant-menu {
    background: $menu-inline-background;
    border: none;

    .ant-menu-submenu {
      &:hover {
        border-color: transparent;
      }

      // add more space for arrow icon
      // arrow icon should not trigger click on title element (do not navigate to some page)
      .ant-menu-submenu-title {
        padding-right: $padding-xxl * 1.7;
      }
    }

    .ant-menu-light {
      background-color: transparent;
    }
  }

  &:not(.ant-menu-horizontal).ant-menu-light,
  &-light,
  &-light .ant-menu-submenu,
  &-light .ant-menu-submenu > .ant-menu {
    color: $menu-light-color;
    font-weight: 500;
    letter-spacing: 0.8px;

    .ant-menu-submenu-arrow:before,
    .ant-menu-submenu-arrow:after {
      background-image: linear-gradient(to right, $menu-light-color, $menu-light-color);
    }

    .ant-menu-item,
    .ant-menu-submenu,
    .menu-submenu,
    .ant-menu-submenu-title {
      &:active,
      &-active,
      &-selected,
      &-active {
        color: $menu-select-color;
        border-color: transparent;
        background: transparent;

        &:after {
          display: none;
        }
      }
    }
  }

  .ant-menu-submenu-active,
  .ant-menu-submenu-selected,
  .menu-submenu-selected {
    & > .ant-menu-submenu-title {
      .ant-menu-submenu-arrow:before,
      .ant-menu-submenu-arrow:after {
        background-image: linear-gradient(to right, $menu-select-color, $menu-select-color);
      }
    }
  }

  &-item:hover,
  &-item-active,
  &:not(&-inline) &-submenu-open,
  &-submenu-active,
  &-submenu-title:hover {
    color: $menu-select-color;
  }

  &-submenu-popup {
    background: transparent;

    &:not(.ant-menu-submenu-hidden) {
      border-color: transparentize($submenu-color, 0.5);
    }

    & > .ant-menu {
      font-size: $menu-font-size;
      padding: $padding-sm 0;
      background: $submenu-background;
      color: $submenu-color;

      .ant-menu-item {
        margin: 0;
        padding-left: $padding-xl;

        &-selected {
          background-color: transparent;
        }

        &:hover {
          background-color: $submenu-active-background;
        }
      }
    }
  }

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled,
  .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: transparentize($menu-light-color, 0.5) !important;
  }

  // custom classes for menu
  &.custom-ant-menu-submenu-focused {
    box-shadow: 0px 0px 4px 3px #00c573;
  }

  // Vertical menu
  &-vertical.ant-menu-root {
    background: $menu-vertical-background;
    border: 1px solid $menu-vertical-border-color;
    padding: $padding-sm 0;
    font-size: $menu-vertical-font-size;
    text-transform: none;

    .ant-menu-item {
      color: $menu-vertical-text-color;
      font-size: $menu-vertical-font-size;
      padding: $menu-vertical-item-padding $menu-vertical-item-padding + 10;
      margin: 0 !important;
      height: $menu-vertical-font-size * 2.5;
      line-height: $menu-vertical-font-size * 1.5;

      &-active,
      &-selected,
      &:hover {
        color: $select-color;
        background: $menu-vertical-active-background !important;
      }
    }
  }
}
