.ant-popover {
  max-width: 50%;

  .ant-popover-inner {
    background: $dropdown-background;
    border: 1px solid $dropdown-border-color;
  }

  .ant-popover-inner-content {
    color: $dropdown-text-color;
  }

  .ant-popover-arrow {
    display: none;
  }
}