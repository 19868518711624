.ant-comment {
  margin: $margin-md 0;
  border: 1px solid $comment-border-color;
  padding: $padding-xs $padding-xl;
}

.ant-comment-nested {
  margin-left: $margin-md;
}

.ant-comment-content-author {
  margin-bottom: $margin-xxs;
}

span.ant-comment-content-author-name {
  color: $comment-author-color;
  font-size: $comment-author-font-size;
  font-weight: 600;
}

span.ant-comment-content-author-time {
  color: $comment-date-color;
  font-size: $comment-date-font-size;
}

.ant-comment-content-detail {
  color: $comment-content-color;
  font-size: $comment-content-font-size;
}

.ant-comment-actions {
  margin-top: $margin-xxs;

  & > li > span {
    color: $text-light-color-4;
    margin-right: 0;

    &:hover {
      color: $select-color;
    }

    &:after {
      content: '|';
      display: inline-block;
      color: $text-light-color-2;
      margin: 0 $margin-xs;
    }
  }

  & > li:last-child > span:after {
    display: none;
  }
}
