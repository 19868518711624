.ant-collapse {
    letter-spacing: .6px;
    background-color: $table-in-modal-background;
    border-color: $table-border-color;

    .ant-collapse-content {
        background-color: $table-in-modal-background;
        border-color: $table-border-color;
    }

    .ant-collapse-item {
        .ant-collapse-header {
            border-color: $table-border-color;
            color: $table-head-text-color;
            font-weight: 500;
        }
    }
}

.ant-collapse>.ant-collapse-item {
    border: none;
}