.ant-select-dropdown {
    &.ant-tree-select-dropdown {
        padding: 0px;

        .ant-select-tree {
            background: $dropdown-background;
        }
    }
}

.ant-select-tree-list .ant-select-tree-treenode {
    padding: $padding-sm $padding-lg;
    color: $select-text-color;

    &-selected,
    &:hover {
        color: $select-color;
        background-color: $dropdown-active-background;

        .ant-select-tree-node-content-wrapper {
            background-color: unset;
        }
    }

    &-active {
        background-color: $dropdown-active-background;
    }
}