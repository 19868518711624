// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import '~antd/dist/antd.less';

// mapping scss veraibles to less
@dark: #000; // $gray-800
@cyan: #01bcd4;
@green: #08964d;

// -------- Colors -----------
@primary-color: #000; // #1890ff
@info-color: @cyan;
@success-color: @green;
@processing-color: #08964d;
@error-color: #da5671;
@highlight-color: @red-5;
@warning-color: @gold-5; // #ffc53d
@normal-color: #fffbfd;

@font-family-no-number: 'EurostileLT', 'Heebo', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
@font-family: @font-family-no-number;
@line-height-base: 1.714285714285714; // 24px

// Base Scaffolding Variables
// ---

@border-radius-base: 0px;

// ICONFONT
// @icon-url               : "/vendors/antd/iconfont/iconfont"; // By default it use alicdn.com
@menu-dark-bg: @dark;
@menu-dark-submenu-bg: #212529;

// Layout
// @layout-header-background       : transparent;

@primary-color: #08964D;