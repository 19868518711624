/* EurostileLT */
@font-face {
  font-family: 'EurostileLT';
  src: url('./EurostileLT/EurostileLTProUnicode.woff') format('woff'), url('./EurostileLT/EurostileLTProUnicode.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: 'EurostileLT';
  src: url('./EurostileLT/EurostileLTProUnicode-Demi.woff') format('woff'),
    url('./EurostileLT/EurostileLTProUnicode-Demi.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: 'EurostileLT';
  src: url('./EurostileLT/EurostileLTProUnicode-Bold.woff') format('woff'),
    url('./EurostileLT/EurostileLTProUnicode-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: 'EurostileLT';
  src: url('./EurostileLT/EurostileLTProUnicode-Oblique.woff') format('woff'),
    url('./EurostileLT/EurostileLTProUnicode-Oblique.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  ascent-override: 100%;
}

@font-face {
  font-family: 'EurostileLT';
  src: url('./EurostileLT/EurostileLTProUnicode-DemiOblique.woff') format('woff'),
    url('./EurostileLT/EurostileLTProUnicode-DemiOblique.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  ascent-override: 90%;
}

@font-face {
  font-family: 'EurostileLT';
  src: url('./EurostileLT/EurostileLTProUnicode-BoldOblique.woff') format('woff'),
    url('./EurostileLT/EurostileLTProUnicode-BoldOblique.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  ascent-override: 50%;
}
