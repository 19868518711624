.ant-drawer-content {
  background: $modal-background;
  border: 2px solid $modal-border-color;
  padding: $modal-padding;

  .ant-drawer-header {
    background: transparent;
    border-bottom: none;

    .ant-drawer-title {
      font-size: $modal-title-font-size;
      color: $modal-title-color;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .ant-drawer-footer {
    padding: math.div($modal-padding, 2) 0 0 0;
    border: none;

    button + button {
      margin-left: math.div($modal-padding, 2);
    }
  }
}

.ant-drawer-close {
  color: $modal-title-color;

  .anticon {
    font-size: $font-size-md;
  }

  &:focus,
  &:hover {
    color: $select-color;
  }
}
