.ant-page-header {
  padding: 0;
  padding-top: $padding-md;
}

.ant-page-header-heading {
  position: relative;
  border-bottom: 2px solid $page-header-border;
  padding: $padding-md 0;
  text-transform: uppercase;

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    bottom: -2px;
    width: math.div($page-header-font-size, 1.5);
    height: 2px;
    background-color: $page-header-color;
  }
}

.ant-page-header-heading-title {
  font-size: $page-header-font-size;
  line-height: $page-header-font-size + 4px;
  font-weight: normal;
  color: $page-header-color;
  margin: 0;
}

.ant-page-header-heading-left {
  margin: 0;
}

.ant-page-header-back-button {
  font-size: $page-header-font-size;
  color: $page-header-color;
}

.ant-page-header-heading-sub-title {
  margin-left: $margin-md;
  font-size: $page-sub-header-font-size;
  color: $page-sub-header-color;
}

@media screen and (max-width: $mobile-screen) {
  .ant-page-header-heading-title {
    font-size: $page-header-font-size-mobile;
  }
}
